import React, { Fragment, lazy, Suspense } from 'react'
import { Route, Routes } from 'react-router-dom'
import './App.css'
import { Layout } from './Layout'
import { Container } from 'react-bootstrap'

const Dashboard = lazy(() => import('./Home/Dashboard'))

function App() {
  return (
    <Container fluid>
      <Layout>
        <Container>
          <Suspense fallback={<div>Loading...</div>}>
            <Routes>
              <Route path='/'>
                <Route path='' element={<Dashboard />} />
              </Route>
            </Routes>
          </Suspense>
        </Container>
      </Layout>
    </Container>
  )
}

export default App
