import React, { Fragment, ReactNode } from 'react'
import Navigation from './Navigation'
import Footer from './Footer'

interface Props {
  children: ReactNode
}

export const Layout = ({ children }: Props) => {
  return (
    <Fragment>
      <Navigation />
      <main
        className='flex-grow-1 h-100 px-2 px-sm-3 px-lg-5'
        style={{ backgroundColor: '#e4f0f7', overflowY: 'scroll' }}
      >
        {children}
      </main>
      <Footer />
    </Fragment>
  )
}
