import React, { Fragment } from 'react'
import { Container, Image, Nav, Navbar } from 'react-bootstrap'
import Logo from '../../assets/Logo/Favicon/WordpressTransparent.png'
import './Navigation.css'

export default function Navigation() {
  return (
    <Fragment>
      <Navbar bg='light' data-bs-theme='light'>
        <Container>
          <Navbar.Brand href='#home'>
            <Image src={Logo} alt='Logo' height={62}></Image>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls='basic-navbar-nav' />
          <Navbar.Collapse id='basic-navbar-nav'>
            <Nav className='me-auto'>
              <Nav.Link style={{ color: 'black' }} href='#home'>
                Home
              </Nav.Link>
              <Nav.Link style={{ color: 'black' }} href='#link'>
                About
              </Nav.Link>
              <Nav.Link style={{ color: 'black' }} href='#link'>
                Contact Us
              </Nav.Link>
              <Nav.Link style={{ color: 'black' }} href='#link'>
                Careers
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Fragment>
  )
}
